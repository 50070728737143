.messages-wrapper {
    .align();
    .list-unstyled;
    position: fixed;
    width: 300px;
    max-width: 100%;
    z-index: 102;
    font-weight: 700;
    left: 48%;
    @media (min-width: @mobile-md) {
        width: 400px;
    }
    @media (min-width: @ipad-v) {
        width: 600px;
    }
}

.message-inner {
    border-width: 2px;
    border-style: solid;
    border-radius: @radius;
    background-color: @white;
    box-shadow: @box-shadow-message;
    cursor: pointer;
    margin: @padding-mobile;
}

.messages {
    .messages-wrapper();

    .notice-msg {
        padding: @padding-mobile;
    }

    .message {
        padding: @padding-tablet @padding-tablet @padding-tablet @padding-tablet + @padding-mobile;
    }

    .message,
    .notice-msg {
        .message-inner();

        &:before,
        ul li:before {
            content: none !important;
        }

        >*:first-child:before {
            top: 50%;
            left: @padding-mini;
        }
    }

    .message-success {
        border-color: @success;
        color: @success;

        >*:first-child:before {
            color: @success;
        }
    }

    .message-error {
        border-color: @yellow-dark;
        color: @yellow-dark;

        >*:first-child:before {
            color: @yellow-dark;
        }
    }
    .close-x {
        display: none;
        &:after{
            display: inline-block;
            content: "\00d7"; /* This will render the 'X' */
        }
    }
    &.static {
        .messages:first-child {
            background: #FFF;
            border: 1px solid;
            border-radius: 5px;
            padding: 10px;
            .message {
                border-width: 1px;
                padding: 1rem 1rem 1rem 4.5rem;
            }
        }

        .close-x {
            display: block;
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 25px;
            line-height: 0.5em;
            cursor: pointer;
        }
    }
}