@import '../../variables';

.account {

    .invoices {
        .content;

        &__head {
            display: flex;

            >h5 {
                width: 25%;
                margin-bottom: 8px;
            }
        }

        &__table {
            display: flex;
            flex-direction: column;

        }

        .invoice {
            display: flex;
            align-items: center;
            padding: 8px 0;
            border-top: 1px solid @text-4;

            @media all and (max-width: @ipad-v) {
                flex-direction: column;
                align-items: center;
            }

            &:first-child {
                margin-top: 8px;
            }

            &.correct {
                border-top: none;
                padding: 0;
            }

            .tooltip {
                @media all and (max-width: @ipad-v) {
                    flex: 1 0 auto;
                }

                &:before {
                    content: '';
                    display: block;
                }
            }

            &--unpaid {
                color: @orange;

                .tooltip,
                .button {
                    color: @orange;
                    border-color: @orange;
                }

                .tooltip {
                    &:before {
                        content: '?';
                    }
                }
            }

            &--outdated {
                color: @red;

                .tooltip,
                .button {
                    color: @red;
                    border-color: @red;
                }

                .tooltip {
                    &:before {
                        content: '!';
                    }
                }
            }

            &--paid {
                color: @green;

                .tooltip,
                .button {
                    color: @green;
                    border-color: @green;
                }

                .tooltip {
                    &:before {
                        content: '\2713';
                    }
                }
            }

            &--corrected {
                color: @yellow-dark;

                .tooltip,
                .button {
                    color: @yellow-dark;
                    border-color: @yellow-dark;
                }
            }

            &__buttons {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                >a {

                    &:not(:first-child) {
                        margin-top: 12px;

                        @media all and (max-width: @ipad-v) {
                            margin-top: 0;
                            margin-left: 10px;
                        }

                        @media all and (max-width: @mobile-md) {
                            margin-top: 6px;
                            margin-left: 0;
                        }
                    }
                }
            }

            >p {
                display: flex;
                width: 25%;
                margin: 0;

                @media all and (max-width: @ipad-v) {
                    width: auto;
                }

                &.invoice {

                    &__buttons {

                        @media all and (max-width: @ipad-v) {
                            flex-direction: row;
                            flex-wrap: wrap;
                            margin-top: 10px;
                        }

                        @media all and (max-width: @mobile-md) {
                            flex-direction: column;
                        }
                    }

                    &__tooltip {
                        width: 5%;
                        font-weight: 600;

                        @media all and (max-width: @ipad-v) {
                            width: auto;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }

        .correction-label {
            text-indent: 4.8%;

            span {
                text-decoration: underline;
            }
        }

        .show-tablet {
            display: none;

            @media all and (max-width: @ipad-v) {
                display: inherit;
            }
        }

        .hide-tablet {

            @media all and (max-width: @ipad-v) {
                display: none;
            }
        }
    }
}

.block-invoices-not-found {
    text-align: center;
    color: @navy;

    a {
        color: @navy;
    }

    .icon {
        margin-bottom: @padding-tablet;
    }

    .button {
        margin-top: @padding-tablet;
    }
}